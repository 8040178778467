import type { MenuProps } from 'antd'
import type { Key, ReactNode } from 'react'
import {
  FaBoxes, FaEuroSign, FaGlobeAmericas, FaMap, FaMapMarkedAlt, FaPaypal,
} from 'react-icons/fa'
import { MdLocationDisabled } from 'react-icons/md'

import {
  DatabaseOutlined as IconDataManagement, GroupOutlined as IconGroupOutlined,
  HomeOutlined as IconHome, ShoppingCartOutlined as IconOrders,
  TeamOutlined as IconUser, UngroupOutlined as IconAccount,
} from '@ant-design/icons'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Dashboard', 'dashboard', <IconHome />),
  getItem('Accounts', 'accountsList', <IconAccount />),
  getItem('Users', 'usersList', <IconUser />),
  getItem('Pins', 'pinsList', <FaMapMarkedAlt />),
  getItem('Pins Map', 'pinsMap', <FaMap />),
  getItem('Pin Sets', 'pinSetsList', <IconGroupOutlined />),
  getItem('Blocked Pins', 'blockedPinNames', <MdLocationDisabled />),
  getItem('Orders', 'storeOrdersList', <IconOrders />),
  { type: 'divider' },
  getItem('Data Management', 'dataManagement', <IconDataManagement />, [
    getItem('Currencies', 'currenciesList', <FaEuroSign />),
    getItem('Countries', 'countriesList', <FaGlobeAmericas />),
    { type: 'divider' },
    getItem('Store Product Groups', 'storeProductGroupsList', <FaBoxes />),
    { type: 'divider' },
    getItem('PayPal Products', 'paypalProductsList', <FaPaypal />),
    getItem('PayPal Plans', 'paypalPlansList', <FaPaypal />),
  ]),
]

export default items
