import { combineReducers } from 'redux'
import createStore from './createStore'

import appStore from './app'

let _store = null

class Store {
  /**
   * Combined reducers to be passed to redux store creation.
   * @return {function} Combined reduce function.
   */
  static getReducers() {
    return combineReducers({
      app: appStore.reducers
    })
  }

  /**
   * Initial store state
   * @return {Object} Store state
   */
  static getInitialState() {
    return {
      app: appStore.initialState
    }
  }

  /**
   * The redux store object.
   * @return {Store} Redux store
   */
  static get() {
    if (!_store) {
      _store = createStore(
        this.getReducers(),
        this.getInitialState(),
      )
    }

    return _store
  }

  static reset() {
    _store = null
  }
}

// export
export default Store
