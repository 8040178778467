import PropTypes from 'prop-types'
import Styles from './StagingNote.module.sass'

const StagingNote = (props) => {
  const className = props.position === 'topLeft'
    ? [Styles.container, Styles.containerTopLeft].join(' ')
    : [Styles.container, Styles.containerBottomRight].join(' ')

  return (
    <div className={className}></div>
  )
}

StagingNote.propTypes = {
  position: PropTypes.oneOf(['topLeft', 'bottomRight']).isRequired
}

export default StagingNote
