const actionTypes = {
  APP_CONFIG_FULL_SET: 'APP_CONFIG_FULL_SET',

  APP_SYNC_LOGGED_IN_STATE_REQUEST: 'APP_SYNC_LOGGED_IN_STATE_REQUEST',
  APP_SYNC_LOGGED_IN_STATE_SUCCESS: 'APP_SYNC_LOGGED_IN_STATE_SUCCESS',
  APP_SYNC_LOGGED_IN_STATE_FAILURE: 'APP_SYNC_LOGGED_IN_STATE_FAILURE',

  APP_SET_CURRENT_USER_INFORMATION: 'APP_SET_CURRENT_USER_INFORMATION',
  APP_SET_CURRENT_USER_SCOPES:      'APP_SET_CURRENT_USER_SCOPES',

  APP_LAYOUT_SIDER_COLLAPSE: 'APP_LAYOUT_SIDER_COLLAPSE'
}

export default actionTypes
