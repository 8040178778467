import OAuthKit from '@vayapin/oauth-kit'
import isString from 'lodash/isString'
import Store from 'Store'
import AppActionCreators from 'Store/app/actionCreators'

const onFetchUserInfoCallback = () => {
  syncUserData()
}

const syncTokenScopes = (scopes) => {
  if (isString(scopes)) scopes = [scopes]
  Store.get().dispatch(AppActionCreators.appSetCurrentUserScopes(scopes))
}

const syncUserData = async () => {
  if (Store.get()) {
    Store.get().dispatch(AppActionCreators.appSetLoggedInState(
      await OAuthKit.isTokenLocalValid() === true
    ))
    Store.get().dispatch(AppActionCreators.appSetCurrentUserInformation(
      await OAuthKit.getUserData()
    ))
  }

  const authData = await OAuthKit.getAuthInfo()
  syncTokenScopes(authData.scope)
}

const OAuthStoreSync = {
  configure: async () => {
    // register hook
    OAuthKit.addUpdateListener(onFetchUserInfoCallback)

    // sync data
    await syncUserData()
    syncTokenScopes(((await OAuthKit.getAuthInfo()) || {}).scope || [])
  }
}

export default OAuthStoreSync
