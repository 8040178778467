import ConfigError from 'utils/errors/ConfigError'
import isPresent from 'utils/isPresent'

import Settings from './Settings'
import {
  ADMIN_URL, COOKIE_SECURE, CS_API_URL, ID_API_URL, ID_OAUTH_ID,
  ID_SINGLE_SIGN_OUT_URL, ID_URL, LOG_REDUX_CHANGES, NODE_ENV, RELEASE,
  SENTRY_DSN, STAGING, WEBAPP_URL,
} from './Values'

function isStringPresent(str?: string | boolean): boolean {
  return isPresent(str) && str !== 'null' && str !== 'undefined'
}

if (!isStringPresent(NODE_ENV)) {
  throw new ConfigError('NODE_ENV missing')
}

if (!isStringPresent(ID_URL)) {
  throw new ConfigError('VITE_ID_URL missing')
}

if (!isStringPresent(ID_API_URL)) {
  throw new ConfigError('VITE_ID_API_URL missing')
}

if (!isStringPresent(ID_OAUTH_ID)) {
  throw new ConfigError('VITE_ID_OAUTH_ID missing')
}

if (!isStringPresent(ADMIN_URL)) {
  throw new ConfigError('VITE_ADMIN_URL missing')
}

if (!isStringPresent(CS_API_URL)) {
  throw new ConfigError('VITE_CS_API_URL missing')
}

if (!isStringPresent(WEBAPP_URL)) {
  throw new ConfigError('VITE_WEBAPP_URL missing')
}

if (!isStringPresent(SENTRY_DSN)) {
  throw new ConfigError('VITE_SENTRY_DSN missing')
}

if (!isStringPresent(RELEASE)) {
  throw new ConfigError('VITE_RELEASE missing')
}

const Config = {
  settings: Settings,
  NODE_ENV,
  ID_URL,
  ID_API_URL,
  ID_OAUTH_ID,
  ID_SINGLE_SIGN_OUT_URL,
  ADMIN_URL,
  CS_API_URL,
  WEBAPP_URL,
  COOKIE_SECURE: COOKIE_SECURE === 'true' || `${JSON.stringify(COOKIE_SECURE || '')}` === 'true',
  LOG_REDUX_CHANGES,
  STAGING: STAGING === 'true' || `${JSON.stringify(STAGING || '')}` === 'true',
  SENTRY_DSN,
  RELEASE,
}

export default Config
