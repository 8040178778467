import { createContext } from 'react'
import {
  RouterContextData,
} from './Types'

export const DEFAULT_DATA: RouterContextData = {
  searchParams: {},
  setSearchParam: (_name, _value, _options) => undefined,
  setSearchParams: (_params, _options) => undefined,
  removeSearchParam: (_name, _options) => undefined,
}

const RouterContext = createContext(DEFAULT_DATA)
RouterContext.displayName = 'RouterContext'

export const Provider = RouterContext.Provider
export const Consumer = RouterContext.Consumer

export default RouterContext
