import { Map, List } from 'immutable'
import _            from 'lodash'
import actionTypes  from './actionTypes'

const reducers = (state = {}, action) => {
  switch (action.type) {
    // APP_CONFIG_FULL_SET
    case actionTypes.APP_CONFIG_FULL_SET:
      return state.withMutations((s) => {
        const data = action.payload || {}

        if (data.NODE_ENV) s.set('NODE_ENV', data.NODE_ENV)

        return s
      })

    // APP_SYNC_LOGGED_IN_STATE_REQUEST
    case actionTypes.APP_SYNC_LOGGED_IN_STATE_REQUEST:
      return state.withMutations((s) => {
        s.set('signInStateFetching', true)
        return s
      })

    // APP_SYNC_LOGGED_IN_STATE_SUCCESS
    case actionTypes.APP_SYNC_LOGGED_IN_STATE_SUCCESS:
      return state.withMutations((s) => {
        s.set('signedIn', action.signedIn === true)
        s.set('signInStateFetching', false)
        return s
      })

    // APP_SYNC_LOGGED_IN_STATE_FAILURE
    case actionTypes.APP_SYNC_LOGGED_IN_STATE_FAILURE:
      return state.withMutations((s) => {
        s.set('signedIn', false)
        s.set('signInStateFetching', false)
        return s
      })

    // APP_SET_CURRENT_USER_INFORMATION
    case actionTypes.APP_SET_CURRENT_USER_INFORMATION:
      return state.withMutations((s) => {
        if (_.isString(action.userInformation.email)) {
          s.setIn(['currentUser', 'email'], action.userInformation.email)
        } else {
          s.setIn(['currentUser', 'email'], null)
        }

        if (_.isArray(action.userInformation.accounts)) {
          s.setIn(['currentUser', 'accounts'], action.userInformation.accounts)
        } else {
          s.setIn(['currentUser', 'accounts'], [])
        }

        if (_.isObject(action.userInformation.currentAccount)) {
          const account = Object.assign({}, action.userInformation.currentAccount)
          account.roles = _.isArray(account.roles) ? List(account.roles) : List([])
          s.setIn(['currentUser', 'currentAccount'], Map(account))
        } else {
          s.setIn(
            ['currentUser', 'currentAccount'],
            Map({ id: null, name: null, type: null, roles: List([]) })
          )
        }

        return s
      })

    // APP_SET_CURRENT_USER_SCOPES
    case actionTypes.APP_SET_CURRENT_USER_SCOPES:
      return state.withMutations((s) => {
        s.setIn(['currentUser', 'scopes'], List(action.scopes || []))
        return s
      })

    // APP_LAYOUT_SIDER_COLLAPSE
    case actionTypes.APP_LAYOUT_SIDER_COLLAPSE:
      return state.withMutations((s) => {
        s.setIn(['layout', 'siderCollapsed'], action.payload === true)
        return s
      })

    default:
      return state
  }
}

export default reducers
