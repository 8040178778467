/* eslint-disable react/prop-types */

import Styles from './ContentCard.module.sass'

export const ContentCardStyles = Styles

const ContentCard = (props) => (
  <div className={Styles.container}>
    {props.children}
  </div>
)

export default ContentCard
