import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from 'redux-logger'
import Config from 'utils/Config'

const create = (reducers, initialState) => {
  let middlewares = null
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // configure middlewares
  if (Config.LOG_REDUX_CHANGES === true) {
    middlewares = applyMiddleware(
      thunkMiddleware,
      loggerMiddleware
    )
  } else {
    middlewares = applyMiddleware(
      thunkMiddleware
    )
  }

  // create store object
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(middlewares)
  )

  // return store
  return store
}

export default create
