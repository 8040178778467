import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import PropTypes from 'prop-types'

const ContentResult = (props) => {
  const navigate = useNavigate()

  if (props.status === '404') {
    return (
      <Result
        status="404"
        title={`404 - ${props.resource} not found`}
        subTitle={`Sorry, the ${props.resource} you're looking for does not exist.`}
        extra={
          <Button type="primary" onClick={() => { navigate(-1) }}>
            Back
          </Button>
        }
      />
    )
  } else if (props.status === 'offline') {
    return (
      <Result
        status="error"
        title="Offline / Unavailable"
        subTitle="Sorry, a server error appeared."
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      />
    )
  } else if (props.status === '500') {
    let subTitle = props.subTitle ? props.subTitle : 'A server error appeared'

    if (_.isString(subTitle)) subTitle = (<p>{subTitle}</p>)

    return (
      <Result
        status="500"
        title="500 - server error"
        subTitle={subTitle}
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      />
    )
  } else {
    return (<></>)
  }
}

ContentResult.propTypes = {
  status: PropTypes.oneOf(['404', 'offline', '500']).isRequired,
  resource: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ])
}

ContentResult.defaultValues = {
  resource: 'resource'
}

export default ContentResult
