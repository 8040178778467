import * as Sentry from '@sentry/react'
import OAuthKit from '@vayapin/oauth-kit'
import OAuthKitReact from '@vayapin/oauth-kit-react'
import OAuthKitWeb from '@vayapin/oauth-kit-web'
import Config from 'utils/Config'
import OAuthApiStoreSync from 'utils/OAuthApiStoreSync'
import OAuthStoreSync from 'utils/OAuthStoreSync'
import CookieStorage from '@vayapin/oauth-kit/storage/CookieStorage'
import LocalStorage from '@vayapin/oauth-kit/storage/LocalStorage'

const cookieSecure = Config.COOKIE_SECURE

export default function configureOAuthKit() {
  OAuthKit.configure({
    clientId: Config.ID_OAUTH_ID,
    clientScope: 'vayapin_admin',
    idHost: Config.ID_URL,
    idApiHost: Config.ID_API_URL,
    redirectUri: `${Config.ADMIN_URL.replace(/\/$/, '')}/oauth-callback`,
    authStorage: new CookieStorage({
      prefix: 'vp-admin',
      cookieSecure,
      sameSite: (key) => key === 'token' ? undefined : 'Strict',
      expires: (key) => {
        switch (key) {
          case 'token':
            return 1/48 // (days) 30 minutes
          case 'refreshToken':
            return 1/48 // (days) 30 minutes
          case 'authData':
            return 2
          case 'userData':
            return 2
          default:
            return undefined
        }
      }
    }),
    userInfoStorage: new LocalStorage({ prefix: 'vp-admin' }),
    // debug: Config.NODE_ENV === 'development',
    debug: false,
    debugHandler,
    exceptionHandler,
  })
  OAuthKitReact.configure({})
  OAuthKitWeb.configure({
    autoRefreshToken: true,
  })

  void OAuthApiStoreSync.configure()
  void OAuthStoreSync.configure()
}

function debugHandler(message: string) {
  Sentry.addBreadcrumb({
    message,
    level: 'debug',
  })
  console.debug(message)
}

function exceptionHandler(error: Error) {
  Sentry.captureException(error)
  console.error(error)
}
