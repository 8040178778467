import OAuthKit from '@vayapin/oauth-kit'
import Config       from 'utils/Config'
import actionTypes  from './actionTypes'

// APP_CONFIG_FULL_SET
const appConfigFullSet = (configObject) => ({
  type: actionTypes.APP_CONFIG_FULL_SET,
  payload: configObject
})

// APP_SYNC_LOGGED_IN_STATE_REQUEST
const appSyncLoggedInStateRequest = () => ({
  type: actionTypes.APP_SYNC_LOGGED_IN_STATE_REQUEST
})

// APP_SYNC_LOGGED_IN_STATE_SUCCESS
const appSyncLoggedInStateSuccess = (signedIn) => ({
  type: actionTypes.APP_SYNC_LOGGED_IN_STATE_SUCCESS,
  signedIn
})

// APP_SYNC_LOGGED_IN_STATE_FAILURE
// const appSyncLoggedInStateFailure = (error) => ({
//   type:   actionTypes.APP_SYNC_LOGGED_IN_STATE_FAILURE,
//   error:  error,
// });

// appSyncLoggedInState
const appSyncLoggedInState = () => async (dispatch, _getState) => {
  dispatch(appSyncLoggedInStateRequest())
  dispatch(appSyncLoggedInStateSuccess(await OAuthKit.isTokenLocalValid() === true))
}

//
const appSetLoggedInState = (signedIn) => (dispatch, _getState) => {
  dispatch(appSyncLoggedInStateSuccess(signedIn === true))
}

//
const appSetCurrentUserInformation = (userInformation) => ({
  type: actionTypes.APP_SET_CURRENT_USER_INFORMATION,
  userInformation
})

//
const appSetCurrentUserScopes = (scopes) => ({
  type: actionTypes.APP_SET_CURRENT_USER_SCOPES,
  scopes
})

//
export const appLayoutSiderCollapse = (collapsed) => {
  Config.settings.set('layoutSiderCollapsed', collapsed === true)

  return {
    type: actionTypes.APP_LAYOUT_SIDER_COLLAPSE,
    payload: collapsed
  }
}

const actionCreators = {
  appConfigFullSet,
  appSyncLoggedInState,
  appSetLoggedInState,
  appSetCurrentUserInformation,
  appSetCurrentUserScopes,
  appLayoutSiderCollapse
}

// export
export default actionCreators
