/* eslint-disable react/prop-types */

import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Styles from './LayoutContentBreadcrumbs.module.sass'

const { Item } = Breadcrumb

const LayoutContentBreadcrumbs = (props) => {
  if (!props.breadcrumbs) return <></>

  const items = _.map(props.breadcrumbs || [], (item, index) => {
    item.key = index
    return item
  })

  return (
    <Breadcrumb className={Styles.breadcrumbs}>
      {items.map((item) => item.path ? (
        <Item key={item.key}>
          <Link to={item.path}>
            {item.title}
          </Link>
        </Item>
      ) : (
        <Item key={item.key}>
          {item.title}
        </Item>
      ))}
    </Breadcrumb>
  )
}

export default LayoutContentBreadcrumbs
