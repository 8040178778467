import { Layout } from 'antd'
import SiderNavigation from 'components/SiderNavigation'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import CContentCard, { ContentCardStyles as CContentCardStyles } from './ContentCard'
import CContentResult from './ContentResult'
import Styles from './index.module.sass'
import CLayoutContent from './LayoutContent'

import {
  appLayoutSiderCollapse
} from 'Store/app/actionCreators'

export const LayoutStyles = Styles
export const LayoutContent = CLayoutContent
export const ContentCard = CContentCard
export const ContentCardStyles = CContentCardStyles
export const ContentResult = CContentResult

class MainLayout extends Component {

  handleSiderCollapse = (collapsed, type) => {
    if (type === 'responsive' && collapsed === true) {
      // eslint-disable-next-line react/prop-types
      this.props.siderCollapse(true)
    } else if (type === 'clickTrigger') {
      // eslint-disable-next-line react/prop-types
      this.props.siderCollapse(collapsed === true)
    }
  }

  render() {
    return (
      <Layout className={Styles.layout}>
        <Layout.Sider
          collapsible
          breakpoint="lg"
          // eslint-disable-next-line react/prop-types
          collapsed={this.props.siderCollapsed}
          className={Styles.sider}
          onCollapse={this.handleSiderCollapse}
        >
          {/* eslint-disable-next-line react/prop-types */}
          <SiderNavigation collapsed={this.props.siderCollapsed} />
        </Layout.Sider>
        <Layout className={Styles.content}>
          <Outlet />
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  siderCollapsed: state.app.getIn(['layout', 'siderCollapsed'])
})

const mapDispatchToProps = (dispatch) => ({
  siderCollapse: (collapsed) => dispatch(appLayoutSiderCollapse(collapsed))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout)
