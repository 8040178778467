import { Button, Col, Dropdown, Layout, MenuProps, Row, Typography } from 'antd'
import isString from 'lodash/isString'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Config from 'utils/Config'

import { UserOutlined as IconUser } from '@ant-design/icons'
import OAuthKit from '@vayapin/oauth-kit'

import Styles from './LayoutContentHeader.module.sass'

// @ts-expect-error
import type { MenuInfo } from 'rc-menu/es/interface'
export const LayoutContentHeaderStyles = Styles

type MenuItem = Required<MenuProps>['items'][number]

const cols = [
  { xs: 20, sm: 21, md: 21, lg: 22 },
  { xs: 4, sm: 3, md: 3, lg: 2 }
]

const MENU_ITEMS: MenuItem[] = [
  {
    key: 'account',
    label: 'Account',
  },
  { type: 'divider' },
  {
    key: 'signOut',
    label: 'Sign out',
  },
]

export interface LayoutContentHeaderProps {
  /**
   * Title string or component
   */
  title?: string | ReactNode;

  /**
   * Subtitle string or component
   */
  subTitle?: string | ReactNode;

  /**
   * Render content inside the header, under the first
   * default row
   */
  additionalContent?: ReactNode;
}

const LayoutContentHeader: FC<LayoutContentHeaderProps> = ({
  title = undefined,
  subTitle = undefined,
  additionalContent = undefined,
}) => {
  const navigate = useNavigate()
  const hasStringTitle = isString(title) ? true : false
  const hasStringSubTitle = isString(subTitle) ? true : false
  const hasAdditionalContent = additionalContent ? true : false

  const onMenuClick = useCallback((info: MenuInfo) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    switch (info?.key) {
      case 'account':
        navigate('/account')
        return
      case 'signOut':
        void OAuthKit.localSignOut()
        window.location.href = [
          Config.ID_SINGLE_SIGN_OUT_URL,
          `redirect_uri=${encodeURI(Config.ADMIN_URL)}`
        ].join('?')
        return
      default: return
    }
  }, [navigate])

  const dropdownMenu = useMemo(() => ({
    onClick: onMenuClick,
    items: MENU_ITEMS,
  }), [onMenuClick])

  // Render
  return (
    <Layout.Header className={Styles.container}>
      <Row className={Styles.mainRow}>
        <Col {...cols[0]} className={Styles.mainRowColCenter}>
          {hasStringTitle && (
            <Typography.Title className={Styles.title}>{title}</Typography.Title>
          )}
          {!hasStringTitle && title}
          {hasStringSubTitle && (
            <Typography.Title className={Styles.subTitle} level={4}>
              {subTitle}
            </Typography.Title>
          )}
          {!hasStringSubTitle && subTitle}
        </Col>
        <Col {...cols[1]} className={Styles.mainRowColRight}>
          <Dropdown menu={dropdownMenu}>
            <Button shape="circle" icon={<IconUser />} />
          </Dropdown>
        </Col>
      </Row>
      {hasAdditionalContent && (
        <div className={Styles.additionalContentRow}>
          {additionalContent}
        </div>
      )}
    </Layout.Header>
  )
}

export default LayoutContentHeader
