import { Map, List } from 'immutable'
import Config from 'utils/Config'
import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import reducers from './reducers'

const initialState = Map({
  // node
  NODE_ENV: null,

  // sign in state
  signedIn: false,
  signInStateFetching: false,

  // user
  currentUser: Map({
    email: null,
    accounts: List([]),
    currentAccount: Map({ id: null, name: null, type: null, roles: List([]) }),
    scopes: List([])
  }),

  // layout
  layout: Map({
    siderCollapsed: Config.settings.get('layoutSiderCollapsed')
  })
})

const app = {
  initialState,
  actionTypes,
  actionCreators,
  reducers
}

export default app
