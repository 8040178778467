import { Base64 } from 'js-base64'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import isPresent from 'utils/isPresent'

export const LOCALSTORAGE_KEY = Base64.encode('vayapin-id-config')
export const LOCALSTORAGE_DATA_KEYS = [
  'layoutSiderCollapsed'
]
export interface SettingsType {
  [key: string]: unknown
}

let data: SettingsType = {}

/**
 * Load data from local storage
 */
export function load(): void {
  let rawData: string | null = window?.localStorage?.getItem(
    Settings.LOCALSTORAGE_KEY
  )
  let _d: SettingsType = {}

  try {
    rawData = Base64.decode(rawData || '')
    _d = JSON.parse(rawData) as SettingsType
    data = reduce(
      Settings.LOCALSTORAGE_DATA_KEYS,
      (memo: SettingsType, key: string) => {
        memo[key] = _d[key]
        return memo
      },
      {}
    )
  } catch (e) {
    data = {}
  }
}

/**
 * Save the current data to localStorage
 */
export function persist(): void {
  const _d: SettingsType = pick(data, ...Settings.LOCALSTORAGE_DATA_KEYS)

  window.localStorage.setItem(
    Settings.LOCALSTORAGE_KEY,
    Base64.encode(JSON.stringify(_d))
  )
}

/**
 * Set a value to the settings
 * @param {String} key Name of the attribute. Needs to be included
 *                     in `LOCALSTORAGE_DATA_KEYS`
 * @param {String|integer} value Value to store
 */
export function set(key: string | undefined | null, value: unknown) {
  if (!key || !isPresent(key)) return

  data[key] = value

  Settings.persist()
}

/**
 * Returns either the value for the passed key or - if undefined - the
 * whole data object.
 * @param {String} key Name of the attribute
 * @return {String|integer} Value
 */
export function get(key: string | undefined = undefined) {
  return key && isPresent(key) ? data[key] : data
}

/**
 * Export
 */
const Settings = {
  LOCALSTORAGE_KEY,
  LOCALSTORAGE_DATA_KEYS,
  load,
  persist,
  save: persist,
  set,
  get,
}
export default Settings
