import each from 'lodash/each'
import omit from 'lodash/omit'
import {
  FC,
  useCallback,
  useMemo,
  ReactNode,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  Provider,
  DEFAULT_DATA,
} from './RouterContext'
import {
  NavigateOptions,
  RouterContextData,
  SearchParams,
} from './Types'

interface RouterContextProviderProps {
  children: ReactNode
}

let internalSearchParams: SearchParams = {}

const RouterContextProvider: FC<RouterContextProviderProps> = ({
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useSearchParams()
  const location = useLocation()

  //
  // Load current params
  const searchParams = useMemo(() => {
    const sP = new URLSearchParams(location?.search)
    const data: SearchParams = {}

    for (const pair of sP.entries()) {
      if (pair[0] && pair[1]) data[pair[0]] = pair[1]
    }

    return data
  }, [location?.search])

  //
  // Apply the params
  const setSearchParamsData = useCallback((params: SearchParams) => {
    const sP = new URLSearchParams()

    // build params
    each<SearchParams>(
      params,
      (value?: string, key?: string) => {
        if (key && value) sP.set(key, value)
      }
    )

    // apply and prevent endless cycles
    const newSearch = sP.toString()
    const oldSearch = location?.search?.replace(/^\?/, '')
    if (oldSearch !== newSearch) {
      setQueryParams(sP, { replace: true })
    }
  }, [location?.search, setQueryParams])

  //
  // Set a single search param
  const setSearchParam = useCallback((
    name: string,
    value: string,
    options?: NavigateOptions
  ) => {
    internalSearchParams = {
      ...internalSearchParams,
      [name]: value
    }
    if (!options?.skipApply) setSearchParamsData(internalSearchParams)
  }, [setSearchParamsData])

  //
  // Set all search params
  const setSearchParams = useCallback((
    params: SearchParams,
    options?: NavigateOptions
  ) => {
    internalSearchParams = { ...params }
    if (!options?.skipApply) setSearchParamsData(internalSearchParams)
  }, [setSearchParamsData])

  //
  // remove a single search param
  const removeSearchParam = useCallback((
    name: string,
    options?: NavigateOptions
  ) => {
    internalSearchParams = omit(internalSearchParams, name)
    if (!options?.skipApply) setSearchParamsData(internalSearchParams)
  }, [setSearchParamsData])

  //
  // Build context data
  const contextData = useMemo<RouterContextData>(() => ({
    ...DEFAULT_DATA,
    searchParams,
    setSearchParam,
    setSearchParams,
    removeSearchParam,
  }), [
    removeSearchParam,
    searchParams,
    setSearchParam,
    setSearchParams,
  ])

  //
  // Render component
  return (
    <Provider value={contextData}>
      {children}
    </Provider>
  )
}

export default RouterContextProvider
