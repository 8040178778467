export const NODE_ENV = import.meta.env.PROD ? 'production' : 'development'
export const ID_URL = import.meta.env.VITE_ID_URL as string
export const ID_API_URL = import.meta.env.VITE_ID_API_URL as string
export const ID_OAUTH_ID = import.meta.env.VITE_ID_OAUTH_ID as string
export const ID_SINGLE_SIGN_OUT_URL = import.meta.env.VITE_ID_SINGLE_SIGN_OUT_URL as string
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL as string
  || import.meta.env.VITE_VERCEL_URL as string
export const CS_API_URL = import.meta.env.VITE_CS_API_URL as string
export const WEBAPP_URL = import.meta.env.VITE_WEBAPP_URL as string
export const COOKIE_SECURE = import.meta.env.VITE_COOKIE_SECURE as string
export const LOG_REDUX_CHANGES = import.meta.env.VITE_LOG_REDUX_CHANGES as string
export const STAGING = import.meta.env.VITE_STAGING as string
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string
export const RELEASE = import.meta.env.VITE_RELEASE as string
  || import.meta.env.VERCEL_GIT_COMMIT_SHA as string
  || import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA as string
