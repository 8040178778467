import { Col, Row } from 'antd'
import RootLayout from 'components/layouts/RootLayout'
import LoadingIndicator from 'components/LoadingIndicator'
import { CSSProperties } from 'react'
import { RouteObject } from 'react-router'

import loadable, { DefaultComponent } from '@loadable/component'

const LoadableFallbackStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
}

const LoadableFallbackColStyle: CSSProperties = {
  textAlign: 'center',
}

const LoadableFallback = () => (
  <Row justify="space-around" align="middle" style={LoadableFallbackStyle}>
    <Col span={10} style={LoadableFallbackColStyle}>
      <LoadingIndicator spinning />
    </Col>
  </Row>
)

function loadableWithFallback<Props>(
  loadFn: (props: Props) => Promise<DefaultComponent<Props>>
) {
  return loadable(loadFn, { fallback: <LoadableFallback /> })
}


/* eslint-disable max-len */
const OAuthCallbackScreen = loadableWithFallback(() => import('screens/OAuthCallbackScreen'))
const OAuthDirectSignInScreen = loadableWithFallback(() => import('screens/OAuthDirectSignInScreen'))
const DashboardScreen = loadableWithFallback(() => import('screens/DashboardScreen'))
const AccountScreen = loadableWithFallback(() => import('components/screens/AccountScreen'))
const AccountsScreen = loadableWithFallback(() => import('screens/AccountsListScreen'))
const AccountShowScreen = loadableWithFallback(() => import('components/screens/AccountShowScreen'))
const AccountBalanceShowScreen = loadableWithFallback(() => import('components/screens/AccountBalanceShowScreen'))
const BlockedPinNamesScreen = loadableWithFallback(() => import('screens/BlockedPinNamesScreen'))
const UsersListScreen = loadableWithFallback(() => import('screens/UsersListScreen'))
const UserShowScreen = loadableWithFallback(() => import('screens/UserShowScreen'))
const PinsListScreen = loadableWithFallback(() => import('screens/PinsListScreen'))
const PinsMapScreen = loadableWithFallback(() => import('screens/PinsMapScreen'))
const PinShowScreen = loadableWithFallback(() => import('screens/PinShowScreen'))
const PinSetsListScreen = loadableWithFallback(() => import('screens/PinSetsListScreen'))
const PinSetShowScreen = loadableWithFallback(() => import('screens/PinSetShowScreen'))
const StoreOrdersListScreen = loadableWithFallback(() => import('components/screens/StoreOrdersListScreen'))
const StoreOrderShowScreen = loadableWithFallback(() => import('components/screens/StoreOrderShowScreen'))
const CountriesListScreen = loadableWithFallback(() => import('components/screens/CountriesListScreen'))
const CountryShowScreen = loadableWithFallback(() => import('components/screens/CountryShowScreen'))
const CurrenciesListScreen = loadableWithFallback(() => import('components/screens/CurrenciesListScreen'))
const CurrencyShowScreen = loadableWithFallback(() => import('components/screens/CurrencyShowScreen'))
const StoreProductGroupsListScreen = loadableWithFallback(() => import('components/screens/StoreProductGroupsListScreen'))
const StoreProductGroupShowScreen = loadableWithFallback(() => import('components/screens/StoreProductGroupShowScreen'))
const PayPalProductsListScreen = loadableWithFallback(() => import('components/screens/PayPalProductsListScreen'))
const PayPalPlansListScreen = loadableWithFallback(() => import('components/screens/PayPalPlansListScreen'))
/* eslint-enable max-len */

const Routes: RouteObject[] = [
  {
    path: '/oauth-callback',
    element: <OAuthCallbackScreen />,
  },
  {
    path: '/oauth-direct-sign-in',
    element: <OAuthDirectSignInScreen />,
  },
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <DashboardScreen />,
        index: true,
      },
      {
        path: '/account',
        element: <AccountScreen />,
      },
      {
        path: '/accounts',
        element: <AccountsScreen />,
      },
      {
        path: '/accounts/:id',
        element: <AccountShowScreen />,
      },
      {
        path: '/accounts/:accountId/accountBalance/:id',
        element: <AccountBalanceShowScreen />,
      },
      {
        path: '/accounts/:accountId/pins/:id',
        // @ts-ignore
        element: <PinShowScreen />,
      },
      {
        path: '/accounts/:accountId/orders/:id',
        element: <StoreOrderShowScreen />,
      },
      {
        path: '/accounts/:accountId/users/:id',
        element: <UserShowScreen />,
      },
      {
        path: '/blocked-pin-names',
        element: <BlockedPinNamesScreen />,
      },
      {
        path: '/users',
        element: <UsersListScreen />,
      },
      {
        path: '/users/:id',
        element: <UserShowScreen />,
      },
      {
        path: '/pins',
        // @ts-ignore
        element: <PinsListScreen />,
      },
      {
        path: '/pins/map',
        element: <PinsMapScreen />,
      },
      {
        path: '/pins/:id',
        // @ts-ignore
        element: <PinShowScreen />,
      },
      {
        path: '/pinSets',
        element: <PinSetsListScreen />,
      },
      {
        path: '/pinSets/:id',
        element: <PinSetShowScreen />,
      },
      {
        path: '/orders',
        element: <StoreOrdersListScreen />,
      },
      {
        path: '/orders/:id',
        element: <StoreOrderShowScreen />,
      },
      {
        path: '/countries',
        element: <CountriesListScreen />,
      },
      {
        path: '/countries/:id',
        element: <CountryShowScreen />,
      },
      {
        path: '/currencies',
        element: <CurrenciesListScreen />,
      },
      {
        path: '/currencies/:id',
        element: <CurrencyShowScreen />,
      },
      {
        path: '/storeProductGroups',
        element: <StoreProductGroupsListScreen />,
      },
      {
        path: '/storeProductGroups/:id',
        element: <StoreProductGroupShowScreen />,
      },
      {
        path: '/paypalProducts',
        element: <PayPalProductsListScreen />,
      },
      {
        path:  '/paypalPlans',
        element: <PayPalPlansListScreen />,
      },
    ],
  },
]
export default Routes
