import { Layout } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import LayoutContentBreadcrumbs from './LayoutContentBreadcrumbs'
import LayoutContentHeader from './LayoutContentHeader'
import LayoutContentFooter from './LayoutContentFooter'
import Styles from './LayoutContent.module.sass'

export const LayoutContentStyles = Styles

const { Content } = Layout

const LayoutContent = (props) => {
  const hasHeader = props.header ? true : false
  const hasFooter = props.footer ? true : false
  const hasBreadcrumbs = _.isArray(props.breadcrumbs) && props.breadcrumbs.length > 0

  const contentClasses = classnames({
    [Styles.contentLayoutContent]: true,
    [Styles.contentLayoutContentWithBreadcrumbs]: !!hasBreadcrumbs
  })

  return (
    <Content className={Styles.container}>
      {hasHeader && props.header}
      {!hasHeader && (
        <LayoutContentHeader
          {...props.headerProps}
          breadcrumbs={props.breadcrumbs}
        />
      )}
      <div id="dashboardScroll" className={Styles.contentContainer}>
        <Layout className={Styles.contentLayout}>
          <Content className={contentClasses}>
            <LayoutContentBreadcrumbs
              breadcrumbs={props.breadcrumbs}
            />
            {props.children}
          </Content>
          {hasFooter && props.footer}
          {!hasFooter && <LayoutContentFooter {...props.footerProps} />}
        </Layout>
      </div>
    </Content>
  )
}

LayoutContent.propTypes = {
  //
  // Pass in a full header component implementation.
  // Attention: assure not breaking the layout.
  // See MainLayout/LayoutContentHeader
  header: PropTypes.element,

  //
  // Properties passed to header.
  // See: MainLayout/LayoutContentHeader
  headerProps: PropTypes.object,

  //
  // Pass in a full footer component implementation.
  // Attention: assure not breaking the layout.
  // See MainLayout/LayoutContentFooter
  footer: PropTypes.element,

  //
  // Properties passed to footer.
  // See: MainLayout/LayoutContentFooter
  footerProps: PropTypes.object,

  //
  // Default false. You can pass an array of objects
  // here to render breadcrumbs. Object attributes:
  //  * title {string}
  //  * path {string} (optional)
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.object)
  ]),

  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

LayoutContent.defaultProps = {
  header: null,
  headerProps: {},
  footer: null,
  footerProps: {},
  breadcrumbs: false
}

export default LayoutContent
