import { Spin } from 'antd'
import PropTypes from 'prop-types'

import {
  LoadingOutlined as IconLoadingOutlined
} from '@ant-design/icons'

const loadingIndicatorIcon = (
  <IconLoadingOutlined spin style={{ fontSize: 24 }} />
)

const LoadingIndicator = (props) => (
  <Spin spinning={props.spinning} indicator={loadingIndicatorIcon}>
    {props.children}
  </Spin>
)

LoadingIndicator.propTypes = {
  spinning: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
}

export default LoadingIndicator
