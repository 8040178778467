const HealthCheck = (props) => {
  const isHealthCheck = /^\/health-check/.test(window.location.pathname)

  return isHealthCheck ? (
    <div>ok</div>
  ) : (
    <>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </>
  )
}

export default HealthCheck
