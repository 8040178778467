import { Layout } from 'antd'

const { Footer } = Layout

const LayoutContentFooter = () => {
  const year = (new Date()).getFullYear()

  return (
    <Footer style={{ textAlign: 'center' }}>
      VayaPin © {year}
    </Footer>
  )
}

export default LayoutContentFooter
