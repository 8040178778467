import Store from 'Store'
import AppActionCreators from 'Store/app/actionCreators'

import OAuthKit from '@vayapin/oauth-kit'

const apiOAuthSyncCallback = async () => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  Store.get().dispatch(AppActionCreators.appSetLoggedInState(
    await OAuthKit.isTokenLocalValid() === true
  ))
}

const OAuthApiStoreSync = {
  configure: async () => {
    OAuthKit.addUpdateListener(apiOAuthSyncCallback)

    // check current state
    if (await OAuthKit.isTokenLocalValid() === false) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      Store.get().dispatch(AppActionCreators.appSetLoggedInState(false))
    } else if (await OAuthKit.isTokenRemoteValid() === false) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      Store.get().dispatch(AppActionCreators.appSetLoggedInState(false))
    } else {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      Store.get().dispatch(AppActionCreators.appSetLoggedInState(true))
    }
  }
}

export default OAuthApiStoreSync
