import './index.sass'

import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter, RouterProvider, useLocation, useNavigationType,
  createRoutesFromChildren, matchRoutes
} from 'react-router-dom'
import isPresent from '@vayapin/utils/isPresent'
import Config from 'utils/Config'
import * as Sentry from '@sentry/react'

import reportWebVitals from './reportWebVitals'
import Routes from './Routes'

Config.settings.load()

const enableSentry = isPresent(Config.SENTRY_DSN)

if (enableSentry) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    release: Config.RELEASE,
    environment: Config.NODE_ENV === 'production'
      ? Config.STAGING ? 'staging' : 'production'
      : 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Config.NODE_ENV === 'production'
      ? Config.STAGING ? 1.0 : 0.1
      : 0.0,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
  })
}

function createSentryRouter() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter(Routes)
}

const router = enableSentry ? createSentryRouter() : createBrowserRouter(Routes)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
