/* eslint-disable react/prop-types */

import { Button, Menu, Modal } from 'antd'
import LogoLargeUrl from 'images/logo_green_white_171x62.png'
import LogoSmallUrl from 'images/logo_pin_green_48x61.png'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './index.module.sass'
import items from './items'

import { ExclamationCircleOutlined } from '@ant-design/icons'

const SiderNavigation = (props) => {
  const navigate = useNavigate()
  const logoUrl = props.collapsed === true ? LogoSmallUrl : LogoLargeUrl

  const confirmDataManagement = useCallback(() => new Promise(
    (resolve, _reject) => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Enter core data management',
        // eslint-disable-next-line max-len
        content: 'You are entering the core data management of the system. Please be very carefuly with editing the data you can find here. Changes might break the whole system or will disable features for a larger group of users.',
        onOk: () => resolve(),
        onCancel: () => {}
      })
    }), [])

  const onClickMenuItem = useCallback((obj) => {
    switch (obj.key) {
      case 'dashboard':
        navigate('/')
        break
      case 'accountsList':
        navigate('/accounts')
        break
      case 'blockedPinNames':
        navigate('/blocked-pin-names')
        break
      case 'usersList':
        navigate('/users')
        break
      case 'pinsList':
        navigate('/pins')
        break
      case 'pinsMap':
        navigate('pins/map')
        break
      case 'pinSetsList':
        navigate('/pinSets')
        break
      case 'storeOrdersList':
        navigate('/orders')
        break
      case 'countriesList':
        confirmDataManagement().then(() =>
          navigate('/countries')
        )
        break
      case 'currenciesList':
        confirmDataManagement().then(() =>
          navigate('/currencies')
        )
        break
      case 'storeProductGroupsList':
        confirmDataManagement().then(() =>
          navigate('/storeProductGroups')
        )
        break
      case 'paypalProductsList':
        confirmDataManagement().then(() =>
          navigate('/paypalProducts')
        )
        break
      case 'paypalPlansList':
        confirmDataManagement().then(() =>
          navigate('/paypalPlans')
        )
        break
      default:
        break
    }
  }, [confirmDataManagement, navigate])

  return (
    <>
      <Button
        type="link"
        className={Styles.logo}
        onClick={() => onClickMenuItem({ key: 'dashboard' })}
      >
        <img src={logoUrl} alt="Vayapin" />
      </Button>
      <Menu
        theme="dark"
        mode="inline"
        className={Styles.menu}
        onClick={(obj) => onClickMenuItem(obj)}
        items={items}
      />
    </>
  )
}

SiderNavigation.propTypes = {
  collapsed: PropTypes.bool
}

export default SiderNavigation
