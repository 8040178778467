import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { ApolloRequestHandler } from '@vayapin/oauth-kit'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import axios from 'axios'
import Config from 'utils/Config'

let _instance = null

class CoreServiceApollo {

  /**
   * The singleton Config instance.
   * @static
   * @return {Config} Instance
   */
  static get() {
    if (!_instance)  _instance = new this()
    return _instance
  }

  /**
   * Reset singleton instance. Config.get() will return a new instance next time called.
   * @static
   */
  static reset() {
    if (_instance) _instance.reset()
    _instance = null
  }

  /**
   * constructor
   */
  constructor() {
    this._inCacheMemory = new InMemoryCache({
      typePolicies: {
        AccountBalance: {
          fields: {
            transactions: {
              merge(_existing, incoming) {
                return incoming
              }
            },
            storePayments: {
              merge(_existing, incoming) {
                return incoming
              }
            }
          }
        }
      }
    })
    this.configureClient()
  }

  /**
   * Returns the full url to the core service graphql server
   * @return {String} url
   */
  getCoreServiceGraphQLUrl() {
    return `${Config.CS_API_URL.replace(/\/$/, '')}/graphql`
  }

  /**
   * Returns the full url including the required scope param
   * @return {String} url
   */
  getCoreServiceGraphQLFullUrl() {
    return [
      this.getCoreServiceGraphQLUrl(),
      'scope=vayapin_admin'
    ].join('?')
  }

  /**
   * Returns the apollo client
   * @return {ApolloClient} client
   */
  getClient() {
    return this._client
  }

  /**
   * Builds and sets the apollo client
   */
  configureClient() {
    // Default link to ensure authentification
    // and JWT retrieving
    const defaultApolloLink = new ApolloLink(
      async (operation, forward) => {
        await ApolloRequestHandler(operation)
        return forward(operation)
      }
    )

    // axios fetch wrapper to get upload progress
    // for file submission
    const axiosFetch = buildAxiosFetch(axios, (config, _input, init) => ({
      ...config,
      onDownloadProgress: init.onDownloadProgress,
      onUploadProgress: init.onUploadProgress
    }))

    // Link options for both upload and default http link
    const linkOptions = {
      uri: this.getCoreServiceGraphQLFullUrl(),
      fetch: axiosFetch
    }

    // Based on context.hasUpload decide whether to use
    // the multipart upload middleware or the default approach
    const link = ApolloLink.split(
      (operation) => operation.getContext().hasUpload,
      concat(defaultApolloLink, createUploadLink(linkOptions)),
      concat(defaultApolloLink, new HttpLink(linkOptions))
    )

    // Build the client
    this._client = new ApolloClient({
      cache: this._inCacheMemory,
      link
    })
  }

  /**
   * Resets instance listeners
   */
  reset() {
    if (this._client) this._client.stop()
    this._client = null
  }
}

export default CoreServiceApollo
